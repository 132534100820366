import {Channel} from "@repo/types/Channel";
import {ApiURL, Request} from "@services/api.service";


interface IChannelDao {
  getOne: (post: string) => Promise<Channel | Error>
}

export default {
  getOne: (channel: string): Promise<Channel | Error> => {
    return Promise.all([
      Request(ApiURL + `channel/info/get`, {
        headers: { "Content-type": "application/json" },
        method: "POST",
        body: JSON.stringify( { channel }),
      }),
      Request(ApiURL + `channel/stat/get`, {
        headers: { "Content-type": "application/json" },
        method: "POST",
        body: JSON.stringify( { channel }),
      }),
    ])
      .then(([a, b]: [any, any]) => {
        return new Channel({...a.content.response, ...b.content.response})
      })
  }
} as IChannelDao


