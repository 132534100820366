import {Post} from "../types/Post";
import {ApiURL, Request} from "@services/api.service";

interface IPostDao {
  getOne: (post: string) => Promise<Post | Error>
}

export default {
  getOne: (post: string): Promise<Post | Error> => {
    return Promise.all([
      Request(ApiURL + `post/info/get`, {
        headers: { "Content-type": "application/json" },
        method: "POST",
        body: JSON.stringify( { post }),
      }),
      Request(ApiURL + `post/stat/get`, {
        headers: { "Content-type": "application/json" },
        method: "POST",
        body: JSON.stringify( { post }),
      }),
    ])
      .then(([a, b]: [any, any]) => {
        return new Post({...a.content.response, ...b.content.response})
      })
  }
} as IPostDao


