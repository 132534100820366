import authService from "@services/auth.service";

const ApiURL = "/api/";

function Request(
    endpoint: string,
    params: { [key: string]: any }
) {
    // console.log(endpoint, params)
    return new Promise(async (resolve, reject) => {
        try {
            if (!params.headers) params.headers = {};
            // NOTE: If user is logged in, insert the auth token into request headers for authorization
            if (authService.isLoggedIn()) params.headers["x-access-token"] = authService.getToken();
            // console.log(params)

            const response = await fetch(endpoint, { ...params });
            if (!response.ok) {
                const data = await response.json(); // Assume always json response
                throw new Error(data.error);
            }
            const data = await response.json(); // Assume always json response

            // If response is okay and no errors, then successful request
            resolve(data)
        } catch (error: any) {
            // NOTE: If it is unauthorized error, then we will auto log user out
            if (error && error.message && error.message === "Unauthorized") {
                // globalLogOutDispatch();
            }
            // Handle error
            reject(error.message || error.error || error);
        }
    })
}

export { Request, ApiURL }

// get<T>(url: string, queryParams?: object): Promise<T>
// {
//     return this.instance.get<T>(url, { params: queryParams })
//       .then(result => result.data);
// };

