import React from "react"
import {ConfigProvider, Button, Spin, Form, Input, Typography, Result, ConfigProviderProps} from "antd";
import { LockOutlined } from "@ant-design/icons";

import { withTranslation } from "react-i18next";

import authService from "../services/auth.service"
import MD5 from "crypto-js/md5";
import withAntdToken from "@components/wrappers/withAntdToken";
import withRouter from "@components/wrappers/withRouter";

const { Text, Title } = Typography;

enum Status {
  isBasic,
  isLoading,
  isError
}

type LoginState = {
  status: Status,
  message: string,
};

class Login extends React.Component<any, LoginState> {
  // static contextType = ConfigProvider

  state: LoginState = {
    status: Status.isBasic,
    message: '',
  }

  handleOk = () => {
    this.setState({ status: Status.isBasic })
  }

  handleSubmit = ({password}: {password: string}) => {
    this.setState({ status: Status.isLoading })
    authService
      .login(MD5(password).toString())
      .then((e: any) => {
        this.props.navigate("/board/balance");
        window.location.reload();
      })
      .catch((e: any) => {
        this.setState({
          status: Status.isError,
          message: e.message,
        })
      })
  }

  render() {
    return (
      <section style={{
        alignItems: "center",
        display: "flex",
        height: "100vh"
      }}>
        <div style={{
          margin: "0 auto",
          width: "380px"
        }}>
          {this.state.status === Status.isError && (
            <Result
              status="error"
              title={this.props.t('Login.error.default')}
              subTitle={this.state.message}
              extra={
                <Button type="primary" key="console" onClick={this.handleOk}>
                  {this.props.t('button.back')}
                </Button>
              }
            />
          )}
          {(this.state.status === Status.isLoading || this.state.status === Status.isBasic) && (
            <Spin spinning={this.state.status === Status.isLoading}>
              <div>
                <Title style={{
                  fontSize: this.props.token.fontSizeHeading2,
                  display: 'inline'
                }}>Ad<p style={{
                  fontSize: this.props.token.fontSizeHeading2,
                  color: '#ba80e6',
                  display: 'inline'
                }}>See</p></Title>
                <Text style={{
                  color: this.props.token.colorTextSecondary,
                  display: 'block'
                }}>
                  {this.props.t('Login.welcome')}
                </Text>
              </div>
              <Form
                name="normal_login"
                initialValues={{
                  remember: true,
                }}
                onFinish={this.handleSubmit}
                layout="vertical"
                requiredMark="optional"
              >
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: this.props.t('Login.error.emptyPassword'),
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined/>}
                    type="password"
                    placeholder={this.props.t('placeholder.password')}
                  />
                </Form.Item>
                <Form.Item style={{marginBottom: "0px"}}>
                  <Button block type="primary" htmlType="submit">
                    {this.props.t('button.enter')}
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          )}
        </div>
      </section>
    );
  }
}

export default withRouter(withAntdToken(withTranslation()(Login)))
