import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import React from "react";
import { ConfigProvider } from "antd";

import authService from "@services/auth.service";

import Login from "@components/Login";
import NotFound from "@components/NotFound";
import Board from "@components/Board"
import Balance from "@components/board/Balance";
import ExportPostStats from "@components/board/ExportPostStats";
import ExportChannelStats from "@components/board/ExportChannelStats";


const App:React.FC = () => {
  // const location = useLocation();
  // const { token } = theme.useToken();

  return (
    <div className="App">
      <ConfigProvider theme={{
        token: {
          colorPrimary: '#9261f1',
        },
      }}>
        <Routes>
          <Route path="board" element={<Board/>}>
            <Route path="balance" element={<Balance/>} />
          </Route>
          { !authService.isLoggedIn() && (
          <>
            <Route path="*" element={<Navigate to={"/user/login"}/>} />
            <Route path="user">
              <Route path="login" element={<Login/>} />
            </Route>
          </>
        )}
          { authService.isLoggedIn() && (
            <>
              {/* redirect */}
              <Route path="" element={<Navigate to={"/board/balance"}/>} />
              {/* user/* */}
              <Route path="user">
                <Route
                  path="login"
                  element={
                    // <Navigate to={false ? location.pathname : "/user/login"}/>
                    <Navigate to={"/board/balance"}/>
                  }
                />
                {/* 404 */}
                <Route path="*" element={<NotFound/>} />
              </Route>
              {/* board/* */}
              <Route path="board" element={<Board/>}>
                <Route path="export-posts" element={<ExportPostStats/>} />
                <Route path="export-channel" element={<ExportChannelStats/>} />
                <Route path="balance" element={<Balance/>} />
                <Route path="" element={<Navigate to={"/board/balance"}/>} />
                <Route path="*" element={<NotFound/>} />
              </Route>
            </>
          )}
        </Routes>
      </ConfigProvider>
    </div>
  );
}

export default App;
