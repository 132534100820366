import React, {useState} from "react";
import {
  Avatar,
  Badge,
  Button,
  Empty,
  Input,
  List,
  Typography,
  Popover,
  Result,
  Space,
  Spin,
  Tooltip,
  Table,
  Flex, notification
} from "antd";
import {
    SettingOutlined,
    QuestionCircleOutlined,
    UpOutlined,
    DownOutlined,
    EditOutlined,
    CopyOutlined,
    CheckOutlined,
    CloseOutlined
} from "@ant-design/icons";

import {useTranslation} from "react-i18next";

// @ts-ignore
import UsersManualExport from '@documents/UsersManual_ExportPostStats.pdf';
import PostDao from "@repo/dao/PostDao";
import {IPost} from "@repo/types/IPost";
import uniqid from 'uniqid';

const { Paragraph, Text } = Typography;

enum Status {
    isBasic,
    isEmpty,
    isLoading,
    isContent,
    isError
}

const UsersManualExportButton: React.FC<any> = ({t}) => (
  <div style={{ textAlign: "left", width: '100%' }}>
    <Text color="#d9d9d9">{ t(`ExportPostStats.search.pleaseRead`) }
      <a href={UsersManualExport} rel="noopener noreferrer" target="_blank">
        <Button
          type="link"
          style={{paddingLeft: 4, paddingRight: 0, color: "#9261f1"}}>
          { t(`ExportPostStats.search.manual`) }
        </Button>
      </a>
    </Text>
  </div>
)

const SettingPopover: React.FC<any> = ({open, onOpenChange}) => (
  <Popover
    placement="bottomRight"
    trigger="click"
    open={open}
    onOpenChange={onOpenChange}
  >
    <Button size="large" icon={<SettingOutlined />} />
  </Popover>
)

const SpinLoading: React.FC<any> = () => (
  <Spin
    tip="Loading"
    size="large"
    style={{marginTop: -40, marginBottom: 0}}
  >
    <div style={{
      padding: 50,
      marginTop: 80,
      marginBottom: 80,
      background: 'rgba(0, 0, 0, 0.05)',
      borderRadius: 4,
    }} />;
  </Spin>
)

const ExportPostStats: React.FC<any> = () => {
    const columns: Array<object> = [
      { title: 'Статус', dataIndex: 'status', key: 'status' },
      { title: 'Дата публикации', dataIndex: 'publicationDate', key: 'publicationDate' },
      { title: 'Время публикации', dataIndex: 'publicationTime', key: 'publicationTime' },
      { title: 'Дата удаления', dataIndex: 'deletionDate', key: 'deletionDate' },
      { title: 'Время удаления', dataIndex: 'deletionTime', key: 'deletionTime' },
      { title: 'Показы', dataIndex: 'viewsCount', key: 'viewsCount' },
      { title: 'URL поста', dataIndex: 'link', key: 'link' },
      { title: 'Likes', dataIndex: 'reactionsCount', key: 'reactionsCount' },
      { title: 'Shares', dataIndex: 'sharesCount', key: 'sharesCount' },
      { title: 'Комментарии', dataIndex: 'commentsCount', key: 'commentsCount' },
    ];

    const [status, setStatus] = useState<Status>(Status.isEmpty)
    const [search, setSearch] = useState<Array<string>>(JSON.parse(localStorage.getItem('ExportPostStats.search') || '[]'))
    const [content, setContent] = useState<Array<any>>([])
    const [settingsOpened, setSettingsOpened] = useState<Number>(0)
    const [searchArrayOpened, setSearchArrayOpened] = useState<Number>(0)

    const {t} = useTranslation()
    const [api, contextHolder] = notification.useNotification();

    const handleSettingsOpenedChange = (value: any) => {
        localStorage.setItem('ExportPostStats.settingsOpened', String(value))
        setSettingsOpened(Number(value));
    };
    const handleSearchArrayOpenedChange = (value: any) => {
        localStorage.setItem('ExportPostStats.searchArrayOpened', String(value))
        setSearchArrayOpened(Number(value));
    };
    const handleSearchClear = () => {
        localStorage.removeItem('ExportPostStats.search')
        setSearch([])
    }
    const handleSearchChange = (value: string) => {
        const test1 = /^(https:\/\/)?t\.me\/([a-z-_01-9]+)\/(\d+)$/i
        const test2 = /^(https:\/\/)?t\.me\/c\/(\d+)\/(\d+)$/i
        const search = value
            .split(/[\t\n\s]+/)
            .filter(Boolean)
            .map(w => w.trim().toLowerCase())
            .filter(e => test1.test(e) || test2.test(e))
        setSearch(search);
        localStorage.setItem('ExportPostStats.search', JSON.stringify(search))
    };
    const handleSearch = () => {
        setStatus(Status.isLoading)
        setContent([])
        Promise
          .all(search.map((url) => PostDao.getOne(url)))
          .then((result) => setContent(result))
          .finally(() => setStatus(Status.isContent))
    }

  const getObjectForUser = (post: IPost): object => {
    if(!post.id) return {
      status: t('ExportPostStats.publication.isNotFound'),
    }
    return {
      publicationDate: post.publicationDate ? post.publicationDate.format("DD.MM.YYYY") : null,
      publicationTime: post.publicationTime ? post.publicationTime.format("HH:mm") : null,
      status: post.isDeleted ? t('ExportPostStats.publication.isDeleted') : t('ExportPostStats.publication.isActive'),
      deletionDate: post.isDeleted && post.deletedAtDate ? post.deletedAtDate.format("DD.MM.YYYY") : null,
      deletionTime: post.isDeleted && post.deletedAtTime ? post.deletedAtTime.format("HH:mm") : null,
      viewsCount: post.viewsCount,
      link: post.link ? `https://${post.link}` : '',
      reactionsCount: post.reactionsCount,
      sharesCount: post.sharesCount,
      commentsCount: post.commentsCount,
    }
  }


  const copyToClipboard = async (e: any) => {
      e.target.setAttribute('loading', 'true')
      try {
          // @ts-ignore
          const cols = columns.map(e => e.dataIndex);
          const data = content
            .map(getObjectForUser)
            .reduce((memo, row: any) => `${memo}${cols.map((c) => row[c]).join("\t")}\n`, '')
          await navigator.clipboard.writeText(data)

          api.success({
              message: t(`ExportPostStats.copyToClipboard.successMessage.title`),
              description: t(`ExportPostStats.copyToClipboard.successMessage.message`),
              showProgress: false,
              pauseOnHover: false,
              closeIcon: null,
              placement: 'topRight',
          })
      } catch {
          api.error({
            message: t(`ExportPostStats.copyToClipboard.errorMessage.title`),
            description: t(`ExportPostStats.copyToClipboard.errorMessage.message`),
            showProgress: false,
            pauseOnHover: false,
            closeIcon: null,
            placement: 'topRight',
          })
      }
  }

  return(
      <>
          <Space.Compact style={{width: '100%'}}>
              <SettingPopover open={!!settingsOpened} onOpenChange={handleSettingsOpenedChange} />
              <Popover
                  content={<>
                      <List
                          style={{maxHeight: '50vh', paddingTop: 5, overflow: "auto"}}
                          itemLayout="horizontal"
                          loading={status === Status.isLoading}
                          dataSource={search}
                          locale={{emptyText:
                              <Empty
                                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                                  description={t('ExportPostStats.searchArray.empty')}
                              />
                          }}
                          renderItem={(url, idx) => (
                              <List.Item key={url} style={{height: 40, marginTop: 10, marginBottom: 0}}>
                                  <Paragraph
                                      copyable={{
                                          icon: [
                                              <CopyOutlined style={{color: '#9261f1'}}/>,
                                              <CheckOutlined style={{color: '#9261f1'}}/>,
                                          ],
                                      }}
                                      ellipsis={{
                                          expandable: 'collapsible',
                                          symbol: (expanded) => expanded
                                              ? <UpOutlined style={{color: '#9261f1'}}/>
                                              : <DownOutlined style={{color: '#9261f1'}}/>
                                      }}
                                      style={{left: 0, width: 450}}
                                      editable={{
                                          icon: <EditOutlined style={{color: '#9261f1'}}/>,
                                          onChange: (value) => handleSearchChange(
                                              search.map((e, i) => i === idx ? value : e ).join(' ')
                                          ),
                                      }}
                                  >{url}</Paragraph>
                              </List.Item>
                          )}
                      />
                  </>}
                  placement="bottomRight"
                  title={<div style={{paddingRight: 10, minWidth: 500}}>
                      <Tooltip placement="top" title={t('ExportPostStats.searchArray.titleHelp')}>
                          <Badge count={<QuestionCircleOutlined/>} style={{ color: '#9261f1', paddingLeft: 20 }}>
                              {t('ExportPostStats.searchArray.title')}
                          </Badge>
                      </Tooltip>
                  </div>}
                  trigger="click"
                  open={!!searchArrayOpened}
                  onOpenChange={handleSearchArrayOpenedChange}>
                      <Badge count={search.length} color="#9261f1" overflowCount={99} style={{zIndex: 1}}>
                          <Avatar
                              style={{
                                  borderRadius: 0,
                                  background: '#fff',
                                  border: '1px solid #d9d9d9',
                                  borderRight: 0,
                                  color: '#000',
                                  cursor: "pointer",
                                  userSelect: "none",
                              }}
                              shape="square"
                              size="large">
                              { !!searchArrayOpened ? (
                                  <UpOutlined/>
                              ) : (
                                  <DownOutlined/>
                              )}

                          </Avatar>
                      </Badge>
              </Popover>
              <Input placeholder={t(`ExportPostStats.search.placeholder`)}
                     size="large"
                     value={search.join(', ')}
                     onPressEnter={handleSearch}
                     onChange={e => handleSearchChange(e.target.value)}
                     onFocus={e => e.target.select()}
                     disabled={Boolean(search.length)}
              />
              <Button
                  size="large"
                  onClick={handleSearchClear}>
                      <CloseOutlined />
              </Button>
              <Button
                  type="primary"
                  size="large"
                  onClick={handleSearch}
                  loading={ status === Status.isLoading }>
                  { t(`ExportPostStats.search.button`) }
              </Button>
          </Space.Compact>
          <UsersManualExportButton t={t}/>
          { status === Status.isEmpty && (
              <Result style={{paddingTop: '25vh'}}
                      icon={<Empty description={false} />}
                      status="warning"
                      title={t(`ExportPostStats.search.empty`)}
              />
          )}
          { status === Status.isContent && (
              <>
                  <Flex justify='flex-end' align='flex-start' style={{marginTop: -20}}>
                      <Button
                          onClick={copyToClipboard}
                          type="text"
                          style={{color: "#9261f1"}}>
                          { t(`ExportPostStats.copyToClipboard.action`) }
                      </Button>
                  </Flex>
                  <Table
                      virtual
                      bordered
                      size="small"
                      style={{
                          paddingTop: 5
                      }}
                      pagination={{pageSize: 999, hideOnSinglePage: true}}
                      scroll={{
                          x: 1350,
                          y: 9000,
                      }}
                      columns={columns}
                      dataSource={
                          content.map((e) => ({key: uniqid(), ...getObjectForUser(e)}))
                      }
                  />
              </>
          )}
          { status === Status.isLoading && (
              <SpinLoading/>
          )}
          {contextHolder}
      </>
  );
}

export default ExportPostStats
